//=== #home-mv コピー文字部分
// 23.08.28変更によりサイズ基準はvw(svw)になる. また配置は与えられた領域の中央にする.

#home-mv .textsets-part {
  color: #fff;
  grid-area: text;
  position: relative;
  width: 100%;

  .textset {
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 133px 10px 0;// 133px = ナビの高さ
    position: absolute;
    top: 0;
    width: 100%;
    @include font_fat_disable;

    h2, p { text-align: center; }

    .pt1-1 {
      font-size: get_vw_for_home_mv_sp(33) * 1vw;
      font-size: get_vw_for_home_mv_sp(33) * 1svw;
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 1.35;
      margin-bottom: getEm(22px, 33px);
      @include mq-up(md) {
        font-size: get_vw_for_home_mv_pc(50) * 1vw;
        font-size: get_vw_for_home_mv_pc(50) * 1svw;
        margin-bottom: 0.20em;
      }
      // @include mq-up(xxl) { font-size: 50px; }
    }//.pt1-1

    .pt1-2 {
      font-size: get_vw_for_home_mv_sp(18) * 1vw;
      font-size: get_vw_for_home_mv_sp(18) * 1svw;
      @include mq-up(md) {
        font-size: get_vw_for_home_mv_pc(24) * 1vw;
        font-size: get_vw_for_home_mv_pc(24) * 1svw;
        letter-spacing: 0.03em;
      }
      // @include mq-up(xxl) { font-size: 24px; }
    }//.pt1-2

    &.pt2, &.pt3 {
      font-size: get_vw_for_home_mv_sp(26) * 1vw;
      font-size: get_vw_for_home_mv_sp(26) * 1svw;
      letter-spacing: 0.03em;
      line-height: getEm(45px, 26px);
      @include mq-up(md) {
        font-size: get_vw_for_home_mv_pc(30) * 1vw;
        font-size: get_vw_for_home_mv_pc(30) * 1svw;
      }
      // @include mq-up(xxl) { font-size: 30px; }

      span {display: block;}
    }//&.pt2, &.pt3

    &.pt3 {
      span {
        @include mq-up(md) { display: inline; }
      }
    }

  }//.textset

}//#home-mv .textsets-part
