/*=== .page.home, .page.flow 共有設定 ===*/
.mainv__sect {
  background-color: $color-primary;
  overflow: hidden;
  position: relative;
  width: 100%;

  .container {
    height: 100%;
    position: relative;
  }//.container

  //=== 'recent works' link
  .link {
    height: 30px;
    width: 156px;
    z-index: 1;
    // position: absolute;
    position: fixed;
    left: 0;
    top: calc(100vh - 50px);
    top: calc(100svh - 50px);// 一緒にスクロールしちゃうのでfixedで上からの位置に変更

    > a {
      align-items: center;
      background-color: $color-primary-dark;
      color: #fff;
      display: flex;
      font-family: $font-family-eng;
      font-weight: 300;
      @include font_fat_disable;
      height: 100%;
      justify-content: center;
      width: 100%;
      @include mq-up(md) {
        transition: background-color 0.15s linear;
        &:hover { background-color:darken($color-primary-dark, 5); }
      }
    }//-a

    &.inactive { opacity: 0; }

  }//link

//=== 'scroll'
  .scrollsign {
    display: none;
    background: url(../img/scrollsign-text.svg) right 0px bottom 1px / auto 25px no-repeat;
    height: 130px;
    width: 13px;
    position: absolute;
    right: 3px;
    top: 150px;
    @include mq-up(md) {
      display: block;
      right: 13px;
    }

    .base {
      background-color: $color-primary-lite;
      overflow: hidden;
      height: 100%;
      width: 1px;
      position: relative;
    }//.base

    .line {
      background-color: #fff;
      height: 23.07692308%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }//.line

  }

}//.mainv__sect
