/*========== home-mainv ==========*/

//=== 23.08.28 変更 ===//
// - サイズの基準は vw / svw とする.
// - テキストはシーン上部の空き領域の中央に配置.
// - spのstreetはデザイン幅586pxに変更 (画面からはみ出す分を考慮する必要がなくなった), ともなって各要素の配置x座標も変わる!!

/*=== [mainv__sect] home 専用設定 (heightが #home-mv 固有のものになる) ===*/
.mainv__sect.home {
  height: 310vh;// `svh`非対応ブラウザ用のフォールバック, 以下同
  height: 310svh;
  // [GSAP] このheightをいじることで, 下層sectionが競り上がってくるタイミングの調整に利用する.
  // 本作ではこの数値になったというだけで, コンテンツにより異なるものだ.
  // また, デバイスサイズにより変更する必要はなさそう.
  // [GSAP]が#home-mvのコンテナとして設置する div.pin-spacer に自動設定されるheighとの兼ね合いで決定する.
  &.hidden { visibility: hidden; }// 次のセクションが上がってきたら消すため
}//.mainv__sect.home



//=== メインビジュアル ===//

#home-mv {//NOTE: height, width は [GSAP]により設定される
  overflow: hidden;
  position: relative;
  visibility: hidden;// [GSAP]により解除される
}//#home-mv



// - デザインheight は sp, pc ともに 900px.
// - text-area の height は ~~sp:458px, pc:341px~~ 余剰領域の中央に配置.
// - scene-area の height は sp:404px, pc:521px
// - 以上合計 sp:862px, pc:862px.
// - いずれも下方に38px残る. ここはブルー背景色のpaddingで,  [recent works] リンクボタンがabsoluteで設置される.
// - 上記数字はいずれも 100svh = 900px の場合.



.home-mv__container {// textとsceneを囲むコンテナ. [GSAP]が#home-mvに設定する grid, height, width と干渉しないように必要.
  display: grid;
  grid-template-areas: 'text' 'scene';
  grid-template-columns: 100%;
  grid-template-rows: 1fr (get_vw_for_home_mv_sp(404) * 1vw);
  grid-template-rows: 1fr get_vw_for_home_mv_sp(404) * 1svw;

  height: 100vh;
  height: 100svh;
  min-height: (get_vw_for_home_mv_sp(900) * 1vw);
  min-height: (get_vw_for_home_mv_sp(900) * 1svw);

  width: 100vw;
  width: 100svw;

  padding-bottom: 38px;// home-mvでは下部に背景色がでる
  position: relative;

  @include mq-up(md) {
    grid-template-rows: 1fr (get_vw_for_home_mv_pc(521) * 1vw);
    grid-template-rows: 1fr get_vw_for_home_mv_pc(521) * 1svw;
    min-height: (get_vw_for_home_mv_pc(900) * 1vw);
    min-height: (get_vw_for_home_mv_pc(900) * 1svw);
  }
}//.home-mv__container
